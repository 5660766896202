import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../constants/api.router.constants";
import { ApiBaseReq, ITagTemplate } from "@/clinic/typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { AnyFn, Fn } from "@vueuse/core";

const TagTemplateService = {
  getTemplates: async (
    params: ApiBaseReq,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<ITagTemplate[]>, AxiosResponse, ITagTemplate[]>> => {
    const { execute } = useAxios<AxiosResponse<ITagTemplate[]>, AxiosResponse, ITagTemplate[]>(
      ApiRouterConstants.API_TAG_TEMPLATES,
      {
        method: "GET",
        params,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
};

export default TagTemplateService;
