import { get, set } from "@vueuse/core";

class DistributionGroupUtils {
  assignTagFormat(tags: any, tagType: any, tagClassCss: null) {
    return tags.map((tag: any) => ({
      value: `${tagType}${tag}`,
      label: tag,
      type: tagClassCss || "additional",
    }));
  }
  assignVisitHistorySetting(
    oppositeSetting: any,
    storedDBSetting: any,
    changeSetting: any,
    pickedVisitHistory: string
  ) {
    set(oppositeSetting, []);
    if (storedDBSetting?.visit_history == pickedVisitHistory) {
      set(changeSetting, storedDBSetting?.visit_history_settings);
    } else {
      set(changeSetting, ["all"]);
    }
  }
  changeCurrentMonthVisitHistory(event: any, currentMonthSettings: any) {
    if (event != "当月履歴すべて") {
      const newVisitHistorySettings = get(currentMonthSettings).filter((template: string) => template != "all");
      set(currentMonthSettings, newVisitHistorySettings);
    } else {
      set(currentMonthSettings, ["all"]);
    }
  }
  changeAllTimeVisitHistory(currentSettings: any, allTimeVisitHistorySettings: any) {
    const lastSetting = currentSettings[currentSettings.length - 1];
    set(allTimeVisitHistorySettings, [lastSetting]);
  }
  initHistorySettings(
    settings: any,
    currentMonthVisitHistorySettings: any,
    allTimeVisitHistorySettings: any,
    visitHistory: any
  ) {
    const historySettings = settings?.visit_history_settings;
    if (["all_time", "current_month"].includes(settings?.visit_history)) {
      if (settings?.visit_history == "all_time") {
        set(visitHistory, "all_time");
        set(allTimeVisitHistorySettings, historySettings);
      } else {
        set(visitHistory, "current_month");
        set(currentMonthVisitHistorySettings, historySettings);
      }
    } else {
      set(visitHistory, "all_time");
      set(allTimeVisitHistorySettings, ["all"]);
    }
  }
}

export default new DistributionGroupUtils();
